//TODO: use css for this
async function fullscreen_button_styling() {
    let isFullscreen = document.fullscreenElement !== null;
    let btn_open = document.getElementById('pswp__icn-fullscreen-request');
    let btn_shut = document.getElementById('pswp__icn-fullscreen-exit');
    if (btn_open) { btn_open.style.display = isFullscreen ? 'none' : 'inline' }
    if (btn_shut) { btn_shut.style.display = isFullscreen ? 'inline' : 'none' }
}

export default async function fullscreen_ui(lightbox) {
    if (!document.fullscreenEnabled) { return }

    lightbox.on('uiRegister', () => {
	    lightbox.pswp.ui.registerElement({
	        name: 'fullscreen',
	        order: 9,
	        isButton: true,
	        title: lightbox.pswp.options.fullscreenTitle ? lightbox.pswp.options.fullscreenTitle : 'fullscreen',
	        html: '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 32 32" width="32" height="32"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-fullscreen-exit"/><use class="pswp__icn-shadow" xlink:href="#pswp__icn-fullscreen-request"/><path d="M8 8v6.047h2.834v-3.213h3.213V8h-3.213zm9.953 0v2.834h3.213v3.213H24V8h-2.834zM8 17.953V24h6.047v-2.834h-3.213v-3.213zm13.166 0v3.213h-3.213V24H24v-6.047z" id="pswp__icn-fullscreen-request"/><path d="M11.213 8v3.213H8v2.834h6.047V8zm6.74 0v6.047H24v-2.834h-3.213V8zM8 17.953v2.834h3.213V24h2.834v-6.047h-2.834zm9.953 0V24h2.834v-3.213H24v-2.834h-3.213z" id="pswp__icn-fullscreen-exit" style="display:none"/></svg>',
	        onClick: () => {
	        	if (document.fullscreenElement === null) {
		            document.querySelector('.pswp').requestFullscreen({navigationUI: 'hide'});
		        } else if (document.exitFullscreen) {
		            document.exitFullscreen();
		        }
	        }
	    });
	});

    lightbox.on('afterInit', () => {

    	const fullscreen_key_ac = new AbortController();

        document.addEventListener('keydown', e => {
        	if (e.code === 'KeyF') {
	            e.preventDefault();
		        if (document.fullscreenElement === null) {
		            document.querySelector('.pswp').requestFullscreen({navigationUI: 'hide'});
		        } else if (document.exitFullscreen) {
		            document.exitFullscreen();
		        }
	        }
        }, { signal: fullscreen_key_ac.signal });

        const removeEventListener = () => {
        	fullscreen_key_ac.abort();
        	lightbox.off('close', removeEventListener)
        };

        lightbox.on('close', removeEventListener);
    });

    lightbox.on('close', () => {
    	//TODO: check if document.fullscreenElement is .pswp, so it plays nicely in potential cases where a parent is fullscreened
        if (document.fullscreenElement !== null && document.exitFullscreen) {
            document.exitFullscreen();
        }
    });

    document.addEventListener('fullscreenchange', fullscreen_button_styling);
}
