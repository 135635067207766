import PhotoswipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';

import "photoswipe/dist/photoswipe.css";

import fullscreen_ui from './photoswipe-lightbox/fullscreen-ui';
import close_ui from './photoswipe-lightbox/close-ui';
import captions_ui from './photoswipe-lightbox/captions-ui';

export {
    fullscreen_ui as pswp_fullscreen_ui,
    close_ui as pswp_close_ui,
    captions_ui as pswp_captions_ui
}

declare const PHOTOSWIPE_VERSION: string; // set globally by Webpack's DefinePlugin

export const pswp_defaults = {
    thumbSelector: 'img',
    initialZoomLevel: 'fit',
    secondaryZoomLevel: 'fill',
    maxZoomLevel: 1.5,
    mouseMovePan: true,
    imageClickAction: 'close',
    tapAction: 'close',
    doubleTapAction: 'zoom-or-close',
    closeTitle: 'uždaryti (x)',
    zoomTitle: 'priartinti (z)',
    fullscreenTitle: 'per visą ekraną (f)',
    arrowPrevTitle: 'ankstesnis',
    arrowNextTitle: 'sekantis',
    pswpModule: `./photoswipe.${PHOTOSWIPE_VERSION}.js`
}

export default PhotoswipeLightbox