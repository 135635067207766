export default async function close_ui(lightbox) {

    lightbox.on('afterInit', () => {

    	const close_key_ac = new AbortController();

        document.addEventListener('keydown', e => {
	        if (e.code === 'KeyX') {
	            e.preventDefault();
	            lightbox.pswp.close();
	        }
	    }, { signal: close_key_ac.signal });

	    lightbox.on('close', () => { close_key_ac.abort() });
    });
}
