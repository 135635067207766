export default function Playful(element) {

    let rect, threshold;

    const mouseLeave = e => {
        element.style.transform = '';
        element.style.transition = '';
        delete element.style.transform, element.style.transition, rect, threshold;
    }

    const mouseEnter = e => {
        threshold = {cur: 3, max: 10};
        rect = (r => {
            return {
                cx: r.left + r.width/2,
                cy: r.top + r.height/2,
                hw: r.width/2,
                hh: r.height/2
            }
        })(element.getBoundingClientRect());
    }

    const mouseMove = e => {
        if (threshold && rect && ++threshold.cur > threshold.max) {
            threshold.cur = 0;
            element.style.transform =
                `rotateX(${(e.clientY - rect.cy) * -1 / rect.hh }deg) ` +
                `rotateY(${(e.clientX - rect.cx) / rect.hw }deg)`;
            element.style.transition = 'transform var(--animation-duration-medium)';
        }
    }

    if (parseInt(window.getComputedStyle(element.parentElement).perspective)) {
        element.addEventListener('mouseenter', mouseEnter)
        element.addEventListener('mouseleave', mouseLeave)
        element.addEventListener('mousemove', mouseMove)
    }
}