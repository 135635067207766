export default async function listen(elements:NodeListOf<HTMLElement>, themeEl:HTMLElement = document.documentElement) {

    const themes = themeEl.getAttribute('data-themes').split(/\s+/);

    elements.forEach(el => el.addEventListener('click', () => {

        let themeIndex = themes.indexOf(themeEl.getAttribute('data-theme'));

        if (themeIndex === -1) {
            themeIndex = Number(window.matchMedia(`(prefers-color-scheme:${themes[1]})`).matches);
        }

        let theme = themes[(themeIndex + 1) % themes.length];
        themeEl.setAttribute('data-theme', theme);
        localStorage.setItem('theme', theme);

    }))
}