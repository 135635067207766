function isExpanded(el:HTMLElement) {
    return el.getAttribute('aria-expanded') === 'true';
}

function setExpanded(el:HTMLElement, expanded:boolean=false) {
    el.setAttribute('aria-expanded', expanded.toString());
}

export default async function listen(elements:NodeListOf<HTMLElement>) {

    let ac = [];

    elements.forEach(async (link:HTMLAnchorElement) => {

        link.addEventListener('click', e => {
            let id = link.getAttribute('data-menu');
            let el = document.getElementById(id);
            if (!el) return;

            e.preventDefault();

            if (typeof ac[id] === 'undefined') {
                ac[id] = new AbortController()
            }

            if (isExpanded(el)) {
                setExpanded(el, false);
                ac[id].abort();
                delete ac[id];
            } else {
                setExpanded(el, true);
                el.querySelectorAll('a[href]').forEach(a => a.addEventListener(
                    'click',
                    setExpanded.bind(null, el, false),
                    { signal: ac[id].signal, passive: true }
                ));
            }
        })
    })
}