import "@fontsource/mulish/variable.css";
import "@fontsource/mulish/variable-italic.css";
import "../styles/screen.css";

enum readyState {
    complete = 'complete',
    interactive = 'interactive',
    loading = 'loading'
}

function isReady(state:readyState=readyState.complete) {
    return state === document.readyState ?
        true :
        state === readyState.interactive && document.readyState === readyState.complete ?
            true :
            false;
}

function whenReady(state:readyState=readyState.complete) {
    return new Promise(resolve =>
        isReady(state) ?
            resolve(state) :
            document.addEventListener('readystatechange', () => isReady(state) ? resolve(state) : void(0))
    )
}

async function each(query:string, fn:(el: HTMLElement) => (Promise<any>|void), state:readyState=readyState.complete) {
    await whenReady(state);
    return Promise.all(Array.from(document.querySelectorAll(query)).map(fn))
}

async function all(query:string, fn:(el: NodeListOf<HTMLElement>) => Promise<any>, state:readyState=readyState.complete) {
    await whenReady(state);
    return fn(document.querySelectorAll(query))
}

// Menu toggle
import menuToggleListen from './modules/menu'
all('a.menu-toggle', menuToggleListen, readyState.interactive)

// Theme toggle
import themeToggleListen from './modules/theme-toggle'
all('#theme-toggle', themeToggleListen, readyState.interactive)

// Swiper
async function swiper(swiperSelector) {
    each(swiperSelector, el => {
        import(/* webpackPrefetch: true */'./modules/swiper').then(({
            default: Swiper,
            Navigation
        }) => {
            new Swiper(el,{
                touchEventsTarget: 'container',
                preventClicksPropagation: true,
                touchMoveStopPropagation: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                centeredSlidesBounds: true,
                breakpoints: {
                     640: {
                        centeredSlides: false,
                        centeredSlidesBounds: false,
                     }
                },
                modules: [Navigation],
                navigation: typeof Navigation === 'function',
                createElements: true
            })
       })
    });
}

whenReady(readyState.interactive).then(async () => {

    const swiper_minwidth = 1280;
    const swiper_selector = '.swiper'

    if (document.documentElement.clientWidth > swiper_minwidth) {
        let resizeListener_ac = new AbortController();
        window.addEventListener('resize', () => {
            if (document.documentElement.clientWidth > swiper_minwidth) {
                resizeListener_ac.abort();
                swiper(swiper_selector);
            }
        }, {signal: resizeListener_ac.signal});
    } else {
        swiper(swiper_selector);
    }
});

// Photoswipe
import {default as PhotoswipeLightbox, pswp_defaults, pswp_fullscreen_ui, pswp_close_ui, pswp_captions_ui} from './modules/photoswipe-lightbox'

whenReady(readyState.interactive).then(async () => {

    // Feature images, author photos, tag pictures and the like.
    const lightbox = new PhotoswipeLightbox({
        ...pswp_defaults,
        gallerySelector: '.photoswipe',
    });

    lightbox.on('itemData', ({ itemData }) => {
        const img = itemData.element.querySelector(lightbox.options.thumbSelector);
        itemData.src = img.getAttribute('src');
        itemData.msrc = img.src;
        itemData.srcset = img.getAttribute('srcset');
        const max_width = 3840;

        const setDimensions = (width, height, thumbCropped=true) => {
            itemData.w = width;
            itemData.h = height;
            itemData.thumbCropped = thumbCropped;
        }

        if (img.naturalWidth !== 0 && img.naturalHeight !== 0) {
              setDimensions(max_width, max_width / (img.naturalWidth / img.naturalHeight), Math.round((img.width / img.height) * 1000) !== Math.round((img.naturalWidth / img.naturalHeight) * 1000))
        } else {
            return new Promise(resolve => {
                img.addEventListener('load', () => { resolve(
                    setDimensions(max_width, max_width / (img.naturalWidth / img.naturalHeight), Math.round((img.width / img.height) * 1000) !== Math.round((img.naturalWidth / img.naturalHeight) * 1000))
                )})
            });
        }
    });

    await Promise.all([
        pswp_fullscreen_ui(lightbox),
        pswp_close_ui(lightbox),
        pswp_captions_ui(lightbox)
    ]);

    lightbox.init();
});

whenReady(readyState.interactive).then(async () => {

    // Gallery images
    const lightbox = new PhotoswipeLightbox({
        ...pswp_defaults,
        gallerySelector: '.kg-gallery-container',
        childSelector: '.kg-gallery-image',
    });

    lightbox.on('itemData', ({ itemData }) => {
        const img = itemData.element.querySelector(lightbox.options.thumbSelector);
        itemData.src = img.getAttribute('src');
        itemData.msrc = img.src;
        itemData.srcset = img.getAttribute('srcset');
        itemData.w = img.getAttribute('width');
        itemData.h = img.getAttribute('height');
        itemData.thumbCropped = Math.round((img.width / img.height) * 1000) !== Math.round((itemData.w / itemData.h) * 1000);
    });

    await Promise.all([
        pswp_fullscreen_ui(lightbox),
        pswp_close_ui(lightbox)
    ]);

    lightbox.init();
});

whenReady(readyState.interactive).then(async () => {

    // Single images inside posts, joined into a gallery, if there are several.
    const lightbox = new PhotoswipeLightbox({
        ...pswp_defaults,
        gallerySelector: '.kg-canvas',
        childSelector: '.kg-image-card > img',
        // thumbSelector: 'img'
    });

    lightbox.on('itemData', ({ itemData }) => {
        const img = itemData.element.querySelector(lightbox.options.thumbSelector);
        if (itemData.element.childNodes.item(0).nodeName === 'A') {
            return;
            itemData.html = `<iframe
                src="${itemData.element.childNodes.item(0).getAttribute('href')}"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                frameborder="0"
                width="100%"
                height="100%"
                style="padding:60px 75px 30px 75px"
            ></iframe>`;
        } else if (img) {
            itemData.src = img.getAttribute('src');
            itemData.msrc = img.src;
            itemData.srcset = img.getAttribute('srcset') || "";
            itemData.w = img.getAttribute('width');
            itemData.h = img.getAttribute('height');
            itemData.thumbCropped = Math.round((img.width / img.height) * 1000) !== Math.round((itemData.w / itemData.h) * 1000);
        }
    });

    await Promise.all([
        pswp_fullscreen_ui(lightbox),
        pswp_close_ui(lightbox)
    ]);

    lightbox.init();
});


// Effects
import Playful from './modules/playful'
each('.playful', el => {
    new Playful(el);
});

// External links open in new tab
each('a', el => {
    if ((el as HTMLAnchorElement).href.substring(0, 1) !== '/' && !(el as HTMLAnchorElement).href.startsWith(document.location.origin)) {
        el.setAttribute('target', '_blank');
    }
});