export default async function captions_ui(lightbox) {

    const caption_find = el => {
        return el.nodeName === 'FIGURE' ?
            el.querySelector('figcaption') :
            typeof el.parentElement !== 'undefined' ?
                caption_find(el.parentElement) :
                null;
    }

    const caption_hide = el => {
        if (el = caption_find(el)) el.classList.add('hidden');
    }

    const caption_show = el => {
        if (el = caption_find(el)) el.classList.remove('hidden');
    }


    let el;

    lightbox.on('change', () => {
        if (el !== lightbox.pswp.currSlide.data.element) {
            if (typeof el !== 'undefined') caption_show(el);
            el = lightbox.pswp.currSlide.data.element;
            caption_hide(el);
        }
    });

    lightbox.on('destroy', () => {
        caption_show(el);
        el = undefined;
    });
}
